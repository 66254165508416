<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-card width="500" class="mx-auto mt-8">
      <v-card-title>Velkommen til Køreskoleguide</v-card-title>
      <v-window :value="accountActivated" vertical>
        <v-window-item :value="false">
          <v-form v-model="isValid" class="pa-4" @submit.prevent="activateAccount">
            <v-col cols="12">
              <div class="mb-6">Indtast dit ønskede kodeord for at aktivere din konto.</div>
              <v-text-field
                outlined
                v-model="newPassword"
                label="Kodeord"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                outlined
                v-model="newPassword2"
                label="Gentag kodeord"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, mustMatch]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keypress.enter="activateAccount()"
              />
              <v-alert v-if="error" class="error white--text" icon="mdi-alert"> Der skete en fejl, konto ikke aktiveret. </v-alert>
              <v-btn class="mt-4" depressed @click="activateAccount" :loading="isLoading" color="success" block :disabled="!isValid">
                Aktiver
              </v-btn>
            </v-col>
          </v-form>
        </v-window-item>
        <v-window-item :value="true">
          <div class="pa-8">
            <div class="text-h5 mb-4">Konto aktiveret</div>
            <p>{{ accountActivatedMessage }}</p>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';

export default {
  name: 'ActivateAccount',
  data: () => ({
    isLoading: false,
    isValid: true,
    accountActivated: false,
    showPassword: false,
    newPassword: null,
    newPassword2: null,
    resetResult: null,
    error: null,
    rules: {
      required: (value) => !!value || 'Skal udfyldes',
    },
  }),
  computed: {
    accountActivatedMessage() {
      return this.resetResult?.isStaff ? 'Din konto er nu aktiveret og du er blevet logget ind.' : 'Din konto er nu aktiveret og du kan logge ind i appen.';
    },
  },
  methods: {
    mustMatch(value) {
      return value === this.newPassword || 'Kodeord er ikke identiske';
    },
    async activateAccount() {
      if (this.isValid) {
        this.isLoading = true;

        try {
          this.resetResult = await apiService.activate({
            username: this.$route.query.email,
            token: this.$route.query.token,
            password: this.newPassword,
          });

          if (this.resetResult.isStaff) {
            await this.$store.dispatch('AUTHENTICATE', { userName: this.$route.query.email, password: this.newPassword });
          }

          this.newPassword = null;
          this.newPassword2 = null;
          this.accountActivated = true;
        } catch (e) {
          this.error = e.message;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style></style>
